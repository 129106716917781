.product-detail-wrap {
	.cus-breadcrumb {
		display: none;
		margin: 12px 0;
	}

	.custom-container {
		&.top {
			@include tablet {
				padding-right: 0;
			}
		}
	}

	.product-detail-main {
		margin-top: 24px;
		@include flex-between-start;

		@include tablet {
			@include prefix(flex-direction, column);
		}

		.left-media {
			width: 888px;
			@include flex-between-start;

			@include tablet {
				width: 100%;
			}

			@include resolution_992_1200 {
				width: 60%;
			}

			@include resolution_1201_1300 {
				width: 60%;
			}

			.product-left-txt {
				width: 96px;
				padding: 0 8px;
				text-align: center;
				background-color: $secondary;
				@include prefix(align-self, stretch);

				ul {
					li {
						color: $white;
						margin-top: 36px;

						@include resolution_992_1200 {
							margin-top: 24px;
						}

						@include resolution_1201_1300 {
							margin-top: 36px;

							&:first-child {
								margin-top: 0;
							}
						}

						&:last-child {
							margin-bottom: 36px;
						}

						img {
							max-width: 48px;
							margin: 0 auto;
						}
					}

					@include resolution_1201_1300 {
						@include flex-center-center;
						@include prefix(flex-direction, column);
						height: 100%;
					}
				}
			}

			.sample-productdetails {
				width: 100%;
				max-width: calc(100% - 112px);

				@include tablet {
					max-width: 100%;
				}
				@include tablet {
					.right-big-img {
						padding-right: 23px;
					}
				}
				@include ultra-mobile {
					.right-big-img {
						padding-right: 12px;
					}
				}

				.right-big-img {
					.enlarge-img {
						z-index: 88;
						box-shadow: 0px 1px 8px 1px rgb(0 0 0 / 60%);
					}
				}

				.preview-slider {
					@include tablet {
						padding-right: 23px;
					}

					@include ultra-mobile {
						padding-right: 12px;
					}

					.player-wrapper {
						position: relative;

						// Below Code Is Same But Writtern Seperately for Better Understanding And Debuggin
						@media screen and (max-width: 991px) {
							padding-top: 74%;
						}
						@media screen and (min-width: 992px) {
							padding-top: 74%;
						}
						
						
						/* Player ratio: 100 / (1280 / 720) */
						.react-player {
							position: absolute;
							top: 0;
							left: 0;
						}
					}

					.slick-slide {
						text-align: center;
						// img {
						// 	@include tablet-above {
						// 		width: auto !important;
						// 	}
						// }
					}
				}

				.thumbnail-slider {
					margin-top: 16px;

					.slick-list {
						margin: 0 -12px;

						@include tablet {
							margin: 0 0 0 -8px;
						}

						.slick-slide {
							cursor: pointer;
							padding: 0 12px;
							margin: 0;

							@include tablet {
								padding: 0 8px;
							}

							img {
								max-height: 96px;

								@include desktop-above {
									height: 96px;
								}
							}
						}

						.slick-track {
							margin-left: 0;
						}
					}
				}
			}
		}

		.product-right-txt {
			width: calc(100% - 908px);

			h1 {
				@include fonts($MarcellusFont, $high, 24px, 400, 32px, 0.03em);
			}

			@include tablet {
				width: 100%;
				margin-top: 16px;

				h1 {
					padding-right: 23px;
				}
			}

			@include ultra-mobile {
				h1 {
					padding-right: 12px;
				}
			}

			@include resolution_992_1200 {
				width: calc(calc(100% - 60%) - 16px);
			}

			@include resolution_1201_1300 {
				width: calc(calc(100% - 60%) - 16px);
			}

			.product-social {
				@include flex-between-center;

				@include tablet {
					margin-top: 4px;
					padding-right: 23px;
				}

				@include ultra-mobile {
					padding-right: 12px;
				}

				p {
					color: $mid;
				}

				.extra-action {
					@include flex-between-center;

					.heart-wishlist {
						display: inline-block;
						transition-duration: 0.15s;
						transition-timing-function: ease-in-out;
						@include hw(24px);

						input {
							@include hw(24px);
							display: inline-block;
							cursor: pointer;
							position: relative;
							transition-duration: 0.15s;
							transition-timing-function: ease-in-out;
							background: url("../../Assets/Images/details-heart-favorites.svg");
							background-position: center;
							background-size: cover;

							&:checked {
								background: url("../../Assets/Images/heart-favorites-fill.svg");
							}

							&:checked:hover {
								background: url("../../Assets/Images/heart-favorites-fill.svg");
							}

							&:hover {
								background: url("../../Assets/Images/heart-favorites-hover.svg");
							}
						}
					}

					.social-share.dropdown {
						margin-left: 24px;

						.dropdown-toggle {
							background-color: transparent !important;
							border: 0 !important;
							box-shadow: none !important;
							outline: none !important;
							padding: 0;

							&:after {
								display: none;
							}
						}

						.dropdown-menu.show {
							@include prefix(border-radius, 4px);
							border: 1px solid $line;
							@include flex-start-center;
							@include prefix(flex-direction, column);
							min-width: auto;
							width: 32px;

							a {
								&:not(:last-child) {
									margin-bottom: 4px;
								}
							}
						}

						.dropdown-item {
							padding: 0;
							text-align: center;
						}
					}
				}
			}

			.mob-filter {
				@include flex-between-center;
				margin-top: 12px;

				.select-group {
					width: calc(100% / 2 - 8px);
					margin-bottom: 0;

					label {
						margin: 0;
					}

					select {
						padding-left: 16px;
					}
				}

				@include tablet-above {
					display: none;
				}

				@include tablet {
					padding-right: 23px;
				}

				@include ultra-mobile {
					padding-right: 12px;
					margin-bottom: 16px;
					margin-top: 0;
				}
			}

			.product-txt-box {
				margin-top: 12px;

				.availibility {
					@include flex-start-center;
					margin-top: 8px;

					p {
						margin-top: 0;

						&.bm {
							color: $mid;
							padding-left: 8px;
						}
					}
				}

				.select-size {
					@include tablet {
						padding-right: 23px;
					}

					@include ultra-mobile {
						padding-right: 12px;
					}
				}

				.product-swatches {
					@include ultra-mobile {
						overflow-y: hidden;
						overflow-x: scroll;
						-ms-overflow-style: none;
						/* IE and Edge */
						scrollbar-width: none;

						/* Firefox */
						&::-webkit-scrollbar {
							display: none;
						}
					}

					@media (min-width: 768px) and (max-width: 991px) {
						margin-right: 23px;
					}
				}

				.sample-wrap {
					@include custom-tablet {
						margin-bottom: 8px;
					}

					&.check-hide-991 {
						@include tablet {
							display: none;
						}
					}

					.box-title {
						margin-top: 9px;

						@include ultra-mobile {
							display: none;
						}
					}
				}

				.sample-main {
					margin: 3px 0 0;

					.sample-checkbox {
						@include flex-start-center;
						@include prefix(flex-wrap, wrap);
						gap: 4px 4px;

						@include ultra-mobile {
							@include prefix(flex-wrap, nowrap);
							gap: 8px 8px;
						}
					}

					.samp-tick {
						@include hw(28px);
						position: relative;
						cursor: pointer;

						@include ultra-mobile {
							@include hw(56px);

							&:last-child {
								display: flex;

								&:after {
									content: "\00a0\00a0\00a0\00a0";
									display: block;
									width: 100%;
									height: 4px;
								}
							}
						}

						label {
							img {
								width: 28px;
								height: 28px;
								@include prefix(border-radius, 4px);
								border: 1px solid $line;
								cursor: pointer;

								&:hover {
									border: 1px solid $high;
								}

								@include ultra-mobile {
									@include hw(56px);
								}
							}

							span {
								width: 28px;
								height: 28px;
								@include prefix(border-radius, 4px);
								border: 1px solid $line;
								display: inline-block;
								cursor: pointer;

								&:hover {
									border: 1px solid $primary;
								}

								@include ultra-mobile {
									@include hw(56px);
								}
							}
						}

						input {
							width: 0;
							height: 0;
							border: 0;
						}

						&.selected {
							&::before {
								content: "";
								text-align: center;
								position: absolute;
								height: 16px;
								width: 16px;
								background: url("../../Assets/Images/check.svg") no-repeat center;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);

								@include tablet {
									// height: 56px;
									// width: 56px;
								}
							}

							img {
								border: 1px solid $high;
							}
						}
					}

					.sample-checkbox-mob {
						@include prefix(display, flex);
						@include prefix(flex-flow, column wrap);
						gap: 8px;
						align-content: flex-start;
						max-height: 184px;

						.no-combi-available {
							margin: 12px auto;
							padding-right: 12px;
							text-align: center;
						}
					}
				}
			}

			.select-box {
				.apply-color {
					position: relative;
					margin-top: 16px;
					border: 1px solid $line;
					@include prefix(border-radius, 4px);
					padding: 13px 16px;

					button {
						position: absolute;
						border: unset;
						right: 12px;
						padding: 0;
						top: 50%;
						transform: translateY(-50%);
						background-color: transparent;

						&:hover {
							border: unset;
						}
					}
				}

				@include tablet {
					padding-right: 23px;
				}

				@include ultra-mobile {
					padding-right: 12px;
				}

				.select-size {
					margin-top: 16px;

					.select-group {
						label {
							display: none;
						}

						select {
							padding-left: 16px;
						}
					}
				}

				.sidepanel-tabs {
					@include flex-between-center;
					margin: 16px 0;

					.on-radio {
						flex-basis: calc(calc(100% / 2) - 8px);
						position: relative;

						input[type="radio"] {
							opacity: 0.011;
							z-index: 100;
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							cursor: pointer;
						}

						label {
							width: 100%;
							text-align: center;
							border: 1px solid $line;
							cursor: pointer;
							z-index: 90;
							padding: 12px 0;
							@include prefix(border-radius, 4px);

							&:hover {
								border-color: $mid;
							}
						}

						input[type="radio"]:checked + label {
							border-color: $primary;
							background-color: $dark-high;
							border-width: 2px;
						}

						input[type="radio"]:hover + label {
							border-color: $primary;
							border-width: 2px;
							background-color: $dark-high;
						}
					}
				}
			}

			.product-price {
				@include flex-between-center;
				margin-top: 9px;

				.price {
					@include flex-start-center;

					p {
						&.specialprice {
							text-decoration: line-through;
							color: $mid;
							padding-right: 8px;
						}
					}
				}

				a {
					border-bottom: 1px solid $high;

					&:hover {
						border-color: transparent;
					}
				}

				@include extra-smalldevice {
					@include prefix(flex-wrap, wrap);

					.finance {
						margin: 4px 0;
					}
				}

				@include tablet {
					padding-right: 23px;
				}

				@include ultra-mobile {
					padding-right: 12px;
				}
			}

			.protection-box {
				@include flex-between-center;
				padding-top: 14px;

				.protect-txt {
					@include flex-start-center;

					img {
						height: 24px;
						width: 24px;
						margin-left: 4px;
						cursor: pointer;
					}
					label {
						width: calc(100% - 24px);
					}
				}

				@include extra-smalldevice {
					.ck-tick {
						label {
							padding-left: 4px;
						}
					}
				}

				@include tablet {
					padding-right: 23px;
				}

				@include ultra-mobile {
					padding-right: 12px;
				}
			}

			.action-btn {
				button {
					width: 100%;
					margin-top: 16px;

					@include extra-smalldevice {
						padding: 0 8px;
					}
				}

				@include ultra-mobile {
					padding-bottom: 32px;
					border-bottom: 1px solid $line;
					padding-right: 12px !important;
				}

				@include tablet {
					padding-right: 23px;
				}

				.fill-link {
					height: 48px;
					@include prefix(border-radius, 4px);
					outline: none;
					border: 1px solid $line;
					padding: 0 24px;
					text-align: center;
					box-sizing: border-box;
					@include prefix(box-shadow, none);
					text-decoration: none;
					@include prefix(transition, all 300ms ease-in);
					background-color: $white;
					@include fonts($InterFont, $high, 14px, 600, 20px, 1.6px);
					text-transform: uppercase;
					width: 100%;
					margin-top: 16px;
					@include flex-center-center;
					&:hover {
						@include prefix(transition, all 300ms ease-in);
						cursor: pointer;
						border-color: $mid;
					}
					@include iphone {
						padding: 0 8px;
						letter-spacing: 1.3px;
					}
					span {
						position: relative;
						&:first-child {
							margin-right: 28px;
							@include iphone {
								margin-right: 20px;
							}
							&:after {
								content: ' ';
								@include hw(20px);
								display: inline-block;
								background-image: url("../../Assets/Images/phone.svg");
								background-position: center;
								background-size: 20px;
								position: absolute;				
								top: 50%;
								right: -24px;
								transform: translate(0, -50%);
							}
						}
					}
				}
			}
		}
	}

	.product-detail-tab {
		padding: 0 0 23px 0;
		margin-top: 32px;

		.product-detail-tab-innerwrap {
			background-color: $dark-high;
		}

		.shadow-box {
			@include prefix(box-shadow, 0px 2px 16px 4px rgba(40, 44, 63, 0.07));
			height: 8px;
			position: relative;
			top: -1px;
			z-index: -1;
		}

		@include ultra-mobile {
			padding: 0 0px 32px 0px;
			margin-top: 24px;
		}

		.nav {
			justify-content: center;
			border-bottom: 0;

			li {
				&:not(:last-child) {
					margin-right: 48px;
				}

				button {
					padding: 0 0 6px;
					border: unset;
					border-bottom: 2px solid transparent;
					text-transform: uppercase;
					@include fonts($MarcellusFont, $low, 24px, 400, 32px, 0.03em);

					&.active {
						color: $high;
						border-color: unset;
						border: unset;
						border-bottom: 2px solid $line;
					}
				}
			}
		}

		.tab-content {
			padding-top: 16px;

			p {
				padding-bottom: 20px;
				@include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);

				a {
					color: $high !important;
					border-bottom: 1px solid $high;

					&:hover {
						border-bottom: 1px solid transparent;
					}
				}
			}

			.dimensions-box {
				@include flex-start-start;
				flex-direction: column;

				@include ultra-mobile {
					@include prefix(flex-wrap, wrap);
				}

				img {
					max-width: 536px;
					margin: 0 24px 0 0;

					@include ultra-mobile {
						width: 100%;
						margin: 0;
					}
				}

				.dimensions-txt {
					width: calc(100% - 536px);

					@include ultra-mobile {
						width: 100%;
						padding: 0;
					}
				}
			}
		}
	}

	.product-accordion {
		.accordion {
			.accordion-item {
				border-left: 0;
				border-right: 0;
				border-bottom: 1px solid $line;

				&:first-child {
					border-top: 0;
				}

				.accordion-header {
					.accordion-button {
						padding: 15px 0px 16px;

						&:not(.collapsed) {
							background-color: $dark-high;
							outline: 0;
							box-shadow: unset;
						}

						&:after {
							@include hw(16px);
							background: url("../../Assets/Images/menu-down.svg") center no-repeat;
							margin-right: 8px;
						}

						&:focus {
							outline: 0;
							border-color: unset;
							box-shadow: unset;
						}
					}
				}

				.accordion-body {
					padding: 0 0 12px;

					p {
						margin-bottom: 20px;

						a {
							color: $high !important;
							border-bottom: 1px solid $high !important;

							&:hover {
								border-bottom: 1px solid transparent !important;
							}
						}
					}
				}
			}
		}
	}

	.related-product {
		padding: 24px 0 20px 0;

		@include ultra-mobile {
			padding: 32px 0 36px 0;
		}

		.sec-title {
			text-align: center;
			margin-bottom: 24px;
		}

		.custom-container {
			@include ultra-mobile {
				padding-right: 0;
			}
		}

		.list-item {
			.image-top {
				display: block;
				height: 0;
				overflow: hidden;
				position: relative;
				z-index: 1;
				padding-bottom: 75%;

				img {
					bottom: 0;
					height: 100%;
					left: 0;
					margin: auto;
					max-width: 100%;
					position: absolute;
					right: 0;
					top: 0;
					// object-fit: cover;
					width: auto;
				}
			}

			.product-details {
				margin-top: 8px;
			}
		}

		.product-slider {
			.without-slider {
				margin: 0 -12px;
				@include flex-start-start();

				.list-item {
					width: calc(100% / 3);
					padding: 0 12px;
				}

				@include tablet {
					margin: 0 -8px;

					.list-item {
						width: calc(100% / 3);
						padding: 0 8px;
					}
				}

				@include ultra-mobile {
					margin: 0 12px 0 0;
					flex-wrap: wrap;

					.list-item {
						width: 100%;
						padding: 0;

						&:not(:last-child) {
							margin-bottom: 16px;
						}
					}
				}
			}
		}
	}
}

.custom-topbar {
	@include flex-center-center();
	padding-top: 8px;
	padding-bottom: 8px;
	background-color: $secondary;
	img {
		max-width: 48px;
	}
	p:last-child {
		// width: calc(100% - 56px);
		margin-left: 8px;
		@include fonts($InterFont, $dark-high, 14px, 600, 20px, 1.6px);
		// display: block;
		// display: -webkit-box;
		// max-width: 100%;
		// -webkit-line-clamp: 2;
		// -webkit-box-orient: vertical;
		// overflow: hidden;
		// text-overflow: ellipsis;
	}
}

.product-left-txt {
	&.desktop {
		@include tablet {
			display: none;
		}
	}
	&.mobile {
		margin: 24px 0;
		@include tablet {
			padding-right: 23px;
		}
		@include ultra-mobile {
			padding-right: 12px;
		}
		@include tablet-above {
			display: none;
		}
		ul {
			background-color: $secondary;
			padding: 16px 12px;
		}
		li {
			@include flex-start-center;
			color: $white;
			img {
				@include hw(48px);
				margin-right: 16px;
			}
			&:not(:last-child) {
				padding-bottom: 12px;
			}
		}
	}
}

.guardsam-popup {
	.modal-dialog {
		max-width: 800px;
		text-align: center;

		@include tablet {
			max-width: 95%;
		}

		@include ultra-mobile {
			max-width: 100%;
			margin: 0;

			.modal-content {
				min-height: 100vh;
			}
		}

		.modal-header {
			justify-content: center;

			.modal-title {
				width: 100%;
			}
		}

		.modal-body {
			max-width: 490px;
			margin: 0 auto 20px;
			text-align: left;

			img {
				width: 390px;
				margin: auto;

				@include ultra-mobile {
					width: 100%;
				}
			}

			ul {
				li {
					list-style: disc;
					margin-bottom: 10px;
				}
			}
		}
	}
}

.fabricinfo-popup {
	.modal-dialog {
		max-width: 800px;
		text-align: left;

		@include tablet {
			max-width: 95%;
		}

		@include ultra-mobile {
			max-width: 100%;
			margin: 0;

			.modal-content {
				min-height: 100vh;
				padding-top: 24px;
			}
		}
	}

	.modal-body {
		.sub-point {
			&:not(:last-child) {
				margin-bottom: 24px;
			}

			.block-content {
				margin-top: 12px;
			}
		}
	}
}

/*Product Detail Page Skeleton Starts*/

.product-detail-wrap.skl {
	@include ultra-mobile {
		overflow-x: hidden;
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
	}

	.breadskl {
		padding: 20px 0 28px 0;
		max-width: 25%;

		@include ultra-mobile {
			max-width: 60%;
			padding: 20px 0 20px 0;
		}
	}

	.product-detail-main.skl {
		.left-media {
			width: 888px;
			@include flex-between-start;

			@include tablet {
				width: 100%;
			}

			@include resolution_992_1200 {
				width: 60%;
			}

			@include resolution_1201_1300 {
				width: 60%;
			}

			.product-left-txt {
				width: 96px;
				padding: 30px 8px 0;
				text-align: center;

				@include media-1299-below {
					padding: 50px 8px 0;
				}

				span {
					min-height: 40px;
					height: inherit;
					margin-top: 72px;

					@include media-1299-below {
						margin-top: 62px;
					}
				}
			}

			.sample-productdetails.skl {
				width: 100%;
				max-width: calc(100% - 112px);

				@include tablet {
					max-width: 100%;
				}

				.preview-slider {
					.slider-img {
						min-height: 576px;
						height: 100%;

						@include ultra-mobile {
							min-height: 388px;
						}

						span {
							min-height: inherit;
							height: inherit;
						}
					}
				}

				.thumbnail-slider.skl {
					@include flex-between-start;
					margin: 16px -8px 0;

					@include tablet {
						padding-right: 23px;
					}

					@include ultra-mobile {
						padding-right: 12px;
						margin: 16px -8px 0;
					}

					.point {
						width: 156px;
						min-height: 96px;
						height: 100%;
						padding: 0 8px;

						@include ultra-mobile {
							padding: 0 8px;
							min-height: 66px;
						}

						span {
							min-height: inherit;
							height: inherit;
						}
					}
				}
			}
		}

		.mob-filter {
			@include flex-between-center;
			margin-top: 12px;
			width: 100%;

			.filter {
				width: calc(100% / 2 - 8px);

				span {
					min-height: 48px;
				}
			}

			@include tablet-above {
				display: none;
			}

			@include tablet {
				padding-right: 23px;
			}

			@include ultra-mobile {
				padding-right: 12px;
			}
		}

		.product-right-txt.skl {
			width: calc(100% - 908px);

			h6 {
				max-width: 60%;
			}

			@include tablet {
				width: 100%;
				margin-top: 16px;

				h6 {
					padding-right: 23px;
				}
			}

			@include ultra-mobile {
				h6 {
					padding-right: 12px;
				}
			}

			@include resolution_992_1200 {
				width: calc(calc(100% - 60%) - 16px);
			}

			@include resolution_1201_1300 {
				width: calc(calc(100% - 60%) - 16px);
			}

			.product-social.skl {
				@include flex-between-center;
				margin-top: 5px;

				p {
					width: 154px;

					span {
						min-height: inherit;
						height: inherit;
					}
				}

				.extra-action {
					@include flex-between-center;

					.action {
						height: 24px;
						width: 24px;
						margin-left: 24px;
					}
				}
			}

			.product-txt-box.skl {
				.product-swatches {
					.sample-wrap {
						p.ll {
							margin-top: 9px;
							max-width: 25%;

							&.check-txt-991 {
								@include tablet {
									display: none;
								}
							}
						}

						.sample-main {
							min-height: 80px;
							height: 100%;
							width: 100%;

							span {
								min-height: inherit;
								height: inherit;
							}
						}
					}
				}

				.select-box {
					.select-btn,
					.on-radio {
						span {
							min-height: 48px;
							margin-top: 16px;
						}
					}

					.availibility {
						span {
							span {
								height: 20px;
								width: 150px;
							}
						}
					}
				}

				.product-price {
					display: block;

					.price {
						display: block;
					}
				}

				.protection-box {
					display: block;
				}

				.action-btn {
					border-bottom: unset;

					@include ultra-mobile {
						padding-right: 12px;
					}

					span {
						min-height: 48px;
						margin-top: 16px;
					}
				}
			}
		}
	}

	.product-detail-tab {
		padding: 32px 0 23px;
		text-align: center;
		box-shadow: unset;

		@include ultra-mobile {
			box-shadow: unset;
			padding: 0;
		}

		ul {
			@include flex-center-center;

			@include ultra-mobile {
				@include prefix(flex-wrap, wrap);
			}

			li {
				&:not(:last-child) {
					margin-right: 48px;

					@include ultra-mobile {
						margin-right: 0;
					}
				}

				@include ultra-mobile {
					&:first-child {
						border-top: 1px solid $line;
					}
				}

				@include ultra-mobile {
					margin-right: 0;
					width: 100%;
					padding: 15px 0px 16px;
					border-bottom: 1px solid $line;
				}

				span {
					height: 40px;
					width: 154px;

					@include tablet {
						width: 134px;
					}

					@include ultra-mobile {
						width: 100%;
					}
				}
			}
		}

		.tab-content {
			text-align: left;

			@include ultra-mobile {
				display: none;
			}

			.tab-cont-first {
				span {
					height: 50px;
					max-width: 70%;
				}
			}

			.tab-cont-second {
				span {
					height: 50px;
					max-width: 50%;
				}
			}

			.tab-cont-fourth {
				span {
					height: 50px;
					max-width: 80%;
				}
			}

			span {
				margin-bottom: 20px;
			}
		}
	}

	.related-product {
		@include ultra-mobile {
			padding: 32px 0 26px 0;
		}

		h6 {
			max-width: 25%;
			margin: 0 auto 24px auto;

			@include ultra-mobile {
				max-width: 55%;
			}
		}

		.product-box {
			@include flex-between-center;

			@include ultra-mobile {
				@include prefix(flex-wrap, wrap);
			}

			.list-item {
				width: calc(100% / 3 - 12px);

				@include ultra-mobile {
					width: calc(100% - 12px);
					margin-bottom: 10px;
				}

				.img-skl {
					min-height: 312px;
					height: 100%;
					display: block;

					@include desktop {
						min-height: 200px;
					}

					@include tablet {
						min-height: 160px;
					}

					@include ultra-mobile {
						min-height: 210px;
					}

					span {
						min-height: inherit;
						height: inherit;
					}
				}

				.name-skl {
					max-width: 30%;
					padding-top: 8px;
				}

				.price-skl {
					max-width: 40%;
					padding-top: 8px;
				}
			}
		}
	}
}

.sub-section {
	.product-right-txt.skl {
		width: auto;

		h6 {
			max-width: 60%;
		}

		@include tablet {
			width: 100%;
			margin-top: 16px;

			h6 {
				padding-right: 23px;
			}
		}

		@include ultra-mobile {
			h6 {
				padding-right: 12px;
			}
		}

		@include resolution_992_1200 {
			width: calc(calc(100% - 60%) - 16px);
		}

		@include resolution_1201_1300 {
			width: calc(calc(100% - 60%) - 16px);
		}

		.product-social.skl {
			@include flex-between-center;
			margin-top: 5px;

			p {
				width: 154px;

				span {
					min-height: inherit;
					height: inherit;
				}
			}

			.extra-action {
				@include flex-between-center;

				.action {
					height: 24px;
					width: 24px;
					margin-left: 24px;
				}
			}
		}

		.product-txt-box.skl {
			.product-swatches {
				.sample-wrap {
					p.ll {
						margin-top: 9px;
						max-width: 25%;

						&.check-txt-991 {
							@include tablet {
								display: none;
							}
						}
					}

					.sample-main {
						min-height: 80px;
						height: 100%;
						width: 100%;

						span {
							min-height: inherit;
							height: inherit;
						}
					}
				}
			}

			.select-box {
				.select-btn,
				.on-radio {
					span {
						min-height: 48px;
						margin-top: 16px;
					}
				}

				.availibility {
					span {
						span {
							height: 20px;
							width: 150px;
						}
					}
				}
			}

			.product-price {
				display: block;

				.price {
					display: block;
				}
			}

			.protection-box {
				display: block;
			}

			.action-btn {
				border-bottom: unset;

				@include ultra-mobile {
					padding-right: 12px;
				}

				span {
					min-height: 48px;
					margin-top: 16px;
				}
			}
		}
	}
}

/*Product Detail Page Skeleton Ends*/

.flowbox_review_widget{
	.fbx-widget-container{
		max-width: unset !important;
	
	}
}

.flowbox_avg_star{
	cursor: pointer;
}